import { createAsyncThunk } from '@reduxjs/toolkit';
import { activateSubscription, changePlan } from 'api';
import { ActivateSubscriptionBody, ChangePlanBody } from 'interfaces';
import { RejectedValue } from 'interfaces/RejectedValue';

export const handleActivateSubscription = createAsyncThunk<
  void,
  ActivateSubscriptionBody,
  { rejectValue: RejectedValue }
>('user/fetchUser', async (requestBody, thunkAPI) => {
  const activateSubRes = await activateSubscription(requestBody);

  if (activateSubRes.status && activateSubRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: activateSubRes.statusText });
  }

  return undefined;
});

export const handleChangePlan = createAsyncThunk<
  void,
  ChangePlanBody,
  { rejectValue: RejectedValue }
>('user/fetchUser', async (requestBody, thunkAPI) => {
  const changePlanRes = await changePlan(requestBody);

  if (changePlanRes.status && changePlanRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: changePlanRes.statusText });
  }

  return undefined;
});
