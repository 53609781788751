import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { UnitSystemSliceState, SetUnitSystemPayload } from 'interfaces';

const initialState: UnitSystemSliceState = {
  unitSystemName: 'metric',
  speedMultiplier: 1,
  distanceMultiplier: 1,
  speedUnit: 'km/h',
  distanceUnit: 'm',
};

export const unitSystemSlice = createSlice({
  name: 'unitSystem',
  initialState,
  reducers: {
    setUnitSystem: (state, action: PayloadAction<SetUnitSystemPayload>) => {
      const { unitSystem } = action.payload;

      state.unitSystemName = unitSystem;
      if (unitSystem === 'metric') {
        state.speedMultiplier = 1;
        state.distanceMultiplier = 1;
        state.speedUnit = 'km/h';
        state.distanceUnit = 'm';
      } else {
        state.speedMultiplier = 0.621371;
        state.distanceMultiplier = 3.28084;
        state.speedUnit = 'mph';
        state.distanceUnit = 'ft';
      }
    },
  },
});

export const { setUnitSystem } = unitSystemSlice.actions;

export const selectUnitSystemName = ({ unitSystem }: RootState): string =>
  unitSystem.unitSystemName;
export const selectSpeedMultiplier = ({ unitSystem }: RootState): number =>
  unitSystem.speedMultiplier;
export const selectDistanceMultiplier = ({ unitSystem }: RootState) =>
  unitSystem.distanceMultiplier;
export const selectSpeedUnit = ({ unitSystem }: RootState): string =>
  unitSystem.speedUnit;
export const selectDistanceUnit = ({ unitSystem }: RootState): string =>
  unitSystem.distanceUnit;

export default unitSystemSlice.reducer;
