import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slider, MenuItem, TextField } from '@material-ui/core';
import {
  selectUnitSystemName,
  selectSpeedMultiplier,
  setUnitSystem,
} from 'features';
import { MarketplaceProps } from 'enums';
import { formatPrice } from 'utils';
import { PlanPricingProps } from 'interfaces/props';
import { ReactComponent as Check } from 'assets/images/check.svg';
import calculatorProps from './calculatorProps';
import styles from './PlanPricing.module.scss';

const {
  packages,
  benchmark,
  weights,
  acrPerCamera,
  camerasDensityPerKilometermRef,
} = calculatorProps;

const PlanPricing: React.FC<PlanPricingProps> = ({
  onChange,
  distance,
  numberOfIntersections,
  setNumberOfUnits,
  selectedService,
  setSelectedService,
  setSelectedPlanId,
}) => {
  const dispatch = useDispatch();
  const [selectedPackage, setSelectedPackage] = useState(packages[0]);
  const unitSystemName = useSelector(selectUnitSystemName);
  const speedMultiplier = useSelector(selectSpeedMultiplier);

  const numberOfCameras = Math.ceil(
    Number(camerasDensityPerKilometermRef) * distance,
  );

  const trafficComplexity =
    Number(weights.weightLength) * distance +
    Number(weights.weightJunctions) * numberOfIntersections;

  const coverageComplexity = 1;

  const numberOfUnits = Math.ceil(
    Number(benchmark.XWUref) * trafficComplexity * coverageComplexity,
  );

  const estimatedAcr = Math.ceil(numberOfCameras * Number(acrPerCamera));

  const treshold1 = 28;
  const treshold2 = 56;

  const selectedPlan =
    selectedPackage.plans[
      numberOfUnits > treshold2 ? 2 : numberOfUnits > treshold1 ? 1 : 0
    ];

  const extraPlanUnits = Math.max(
    0,
    numberOfUnits - Number(selectedPlan?.unitsIncluded),
  );

  const sassPrice =
    Number(selectedPlan?.flatRate) +
    extraPlanUnits * Number(selectedPlan?.pricePerUnit);

  const feePerCamera = 50;

  const commDataFee = feePerCamera * numberOfCameras;

  const onSliderChange = (field: string, value: number | number[]) => {
    onChange({
      field,
      value,
    });
  };

  const onPackageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pkg = packages.find((p) => p.name === event.target.value);
    if (pkg) {
      setSelectedPackage(pkg);
      if (setSelectedService) {
        setSelectedService(pkg.name);
      }
    }
  };

  const onLengthUnitChange = (event: React.MouseEvent<HTMLElement>) => {
    const el = event.target as HTMLElement;
    dispatch(
      setUnitSystem({
        unitSystem: el.classList.contains('metric') ? 'metric' : 'imperial',
      }),
    );
  };

  useEffect(() => {
    if (setNumberOfUnits) {
      setNumberOfUnits(numberOfUnits);
    }
  }, [numberOfUnits]);

  useEffect(() => {
    if (setSelectedPlanId) {
      setSelectedPlanId(selectedPlan?.planId);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedService) {
      const pkg = packages.find((p) => p.name === selectedService);
      if (pkg) {
        setSelectedPackage(pkg);
      }
    }
  }, [selectedService]);

  return (
    <div className={styles.root}>
      <div className={styles.calculatorInput}>
        <div className={styles.col}>
          <div className={styles.sliders}>
            <div
              className={styles['sliders-slider']}
              data-testid="slider-distance"
            >
              <div className={styles.heading}>Road Length</div>
              <Slider
                value={Number(distance)}
                min={1}
                max={1000}
                valueLabelDisplay="on"
                marks={[
                  { value: 1, label: '1' },
                  {
                    value: 1000,
                    label: Math.round(1000 * speedMultiplier),
                  },
                ]}
                valueLabelFormat={(value) =>
                  `${Math.round(value * speedMultiplier)} ${
                    unitSystemName === 'metric' ? 'km' : 'miles'
                  }`
                }
                onChange={(_, value) =>
                  onSliderChange(MarketplaceProps.DISTANCE, value)
                }
              />
            </div>
            <div
              className={styles['sliders-slider']}
              data-testid="slider-intersections"
            >
              <div className={styles.heading}>
                Number of Signalized Intersections
              </div>
              <Slider
                value={Number(numberOfIntersections)}
                min={1}
                max={1000}
                valueLabelDisplay="on"
                marks={[
                  { value: 1, label: '1' },
                  { value: 1000, label: '1000' },
                ]}
                onChange={(_, value) =>
                  onSliderChange(
                    MarketplaceProps.NUMBER_OF_INTERSECTIONS,
                    value,
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.packageSelect}>
            <div className={styles.heading}>Select a Service</div>
            <TextField
              select
              value={selectedPackage.name}
              onChange={onPackageChange}
              variant="outlined"
            >
              {packages.map((pkg) => (
                <MenuItem key={pkg.name} value={pkg.name}>
                  {pkg.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={styles.unitSwitch}>
            <button
              className="switch"
              onClick={onLengthUnitChange}
              type="button"
            >
              <div
                className={`metric ${
                  unitSystemName === 'metric' ? 'active' : ''
                }`}
              >
                METRIC
              </div>
              <div className={unitSystemName === 'imperial' ? 'active' : ''}>
                IMPERIAL
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.suggestedPlan}>
        <div className={`${styles.heading} ${styles['suggestedPlan-title']}`}>
          <span className={styles['suggestedPlan-title-icon']}>
            <Check />
          </span>
          Suggested Plan
        </div>
        <div className={styles['suggestedPlan-planName']}>
          <span className={styles['suggestedPlan-package']}>
            {`${selectedPackage.name} `}
          </span>
          {selectedPlan?.name}
          {extraPlanUnits ? ` + ${extraPlanUnits} X Way Units` : ''}
        </div>
        <div className={styles['suggestedPlan-price']}>
          <div data-testid="plan-price-line-1">
            {`SaaS Price*: $${formatPrice(Number(sassPrice))} per month`}
          </div>
          <div data-testid="plan-price-line-2">
            {`Estimated ACR**: $${formatPrice(estimatedAcr)} per month`}
          </div>
          <div data-testid="plan-price-line-3">
            {`Estimated Communication/Data Fee***: $${commDataFee} per month`}
          </div>
        </div>
        <div className={styles['suggestedPlan-footnotes']}>
          * Prices stated do not include any sales, use or excise tax or any
          other tax, duty or charge which is now in effect or may be hereafter
          imposed by any Federal, State or other authority
          <br />
          ** Solution is implemented on Customer&apos;s tenant. Estimated
          Monthly ACR figures are only estimations. Actual ACR fee will be
          handled by Microsoft based on the Customer&apos;s agreement and
          T&amp;C
          <br />
          *** Estimated Monthly Communicate/Data cost figures are only
          estimations, based on common data plan of $50/month for 10GB usage
        </div>
      </div>
    </div>
  );
};

export default PlanPricing;
