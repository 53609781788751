export default {
  packages: [
    {
      name: 'X Way Pulse',
      plans: [
        {
          name: 'Starter',
          flatRate: '900',
          unitsIncluded: '14',
          pricePerUnit: '70',
          planId: 'xwpstarter',
        },
        {
          name: 'Standard',
          flatRate: '1430',
          unitsIncluded: '22',
          pricePerUnit: '65',
          planId: 'xwpstandard',
        },
        {
          name: 'Pro',
          flatRate: '3000',
          unitsIncluded: '50',
          pricePerUnit: '60',
          planId: 'xwppro',
        },
      ],
    },
    {
      name: 'X Way (Pulse + Twin)',
      plans: [
        {
          name: 'Starter',
          flatRate: '1260',
          unitsIncluded: '14',
          pricePerUnit: '90',
          planId: 'xwptstarter',
        },
        {
          name: 'Standard',
          flatRate: '1760',
          unitsIncluded: '22',
          pricePerUnit: '80',
          planId: 'xwptstandard',
        },
        {
          name: 'Pro',
          flatRate: '3750',
          unitsIncluded: '50',
          pricePerUnit: '75',
          planId: 'xwptpro',
        },
      ],
    },
    {
      name: 'X Way (Pulse + Twin + Neural)',
      plans: [
        {
          name: 'Starter',
          flatRate: '1680',
          unitsIncluded: '14',
          pricePerUnit: '120',
          planId: 'xwptnstarter',
        },
        {
          name: 'Standard',
          flatRate: '2530',
          unitsIncluded: '22',
          pricePerUnit: '115',
          planId: 'xwptnstandard',
        },
        {
          name: 'Pro',
          flatRate: '5250',
          unitsIncluded: '50',
          pricePerUnit: '105',
          planId: 'xwptnpro',
        },
      ],
    },
  ],
  benchmark: {
    XWUref: '5.625',
  },
  weights: {
    weightLength: '0.01',
    weightJunctions: '0.02',
    weightCoverage: '0.5',
  },
  acrPerCamera: '500',
  camerasDensityPerKilometermRef: '0.204',
};
