import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingOverlay } from '@axilion/ui-components';
import { selectIsLoading } from 'features';
import { handleLoadingState, handleResponseErrors } from 'utils';
import Pricing from './Pricing/Pricing';
import Install from './Install/Install';
import Profile from './Profile/Profile';
import UserInfo from './UserInfo/UserInfo';
import ChargeExtra from './ChargeExtra/ChargeExtra';
import ManageSubscription from './ManageSubscription/ManageSubscription';
import Footer from './common/Footer/Footer';

const AppRouter = () => {
  const isLoading = useSelector(selectIsLoading);

  handleLoadingState();
  handleResponseErrors();

  return (
    <Router>
      {isLoading && <LoadingOverlay />}
      <Switch>
        <Route path="/allocate" exact component={Install} />
        <Route path="/profile" component={Profile} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/change-plan-activate" component={ManageSubscription} />
        <Route path="/charge-extra" component={ChargeExtra} />
        <Route exact path="/" component={UserInfo} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default AppRouter;
