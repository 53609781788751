import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { fetchChargeExtra, setChargeExtra } from 'features';
import { ChargeExtraData, ChargeExtraSliceState } from 'interfaces';

const initialState: ChargeExtraSliceState = {
  extraCharges: [],
};

export const chargeExtraSlice = createSlice({
  name: 'chargeExtra',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchChargeExtra.fulfilled,
      (store, action: PayloadAction<Array<ChargeExtraData>>) => {
        store.extraCharges = action.payload;
      },
    );

    builder.addCase(fetchChargeExtra.rejected, () => {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/authorize?state=${process.env.REACT_APP_REDIRECT_URL}/%23/charge-extra`,
        '_self',
      );
    });

    builder.addCase(
      setChargeExtra.fulfilled,
      (state, action: PayloadAction<Array<ChargeExtraData>>) => {
        state.extraCharges = action.payload;
      },
    );
  },
});

export const selectExtraCharges = ({
  chargeExtra,
}: RootState): Array<ChargeExtraData> => chargeExtra.extraCharges;
