import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  fetchMarketplaceUserDetails,
  fetchUser,
  storeSubscription,
} from 'features';
import {
  MarketplaceDetails,
  PrincipalResponse,
  UserSliceState,
} from 'interfaces';
import { MarketplaceProps } from 'enums';

const initialState: UserSliceState = {
  fullName: '',
  email: '',
  country: '',
  picture: '',
  isLoggedIn: false,
  marketplaceDetails: {
    [MarketplaceProps.AXILION_INFO]: '',
    [MarketplaceProps.COUNTRY]: '',
    [MarketplaceProps.DEPARTMENT]: '',
    [MarketplaceProps.EMAIL]: '',
    [MarketplaceProps.FAMILY_NAME]: '',
    [MarketplaceProps.GIVEN_NAME]: '',
    [MarketplaceProps.JOB_TITLE]: '',
    [MarketplaceProps.ORG_NAME]: '',
    [MarketplaceProps.ORG_TYPE]: '',
    [MarketplaceProps.PHONE_NUMBER]: '',
    [MarketplaceProps.TITLE]: '',
    [MarketplaceProps.NUMBER_OF_UNITS]: '',
    [MarketplaceProps.DISTANCE]: '',
    [MarketplaceProps.NUMBER_OF_INTERSECTIONS]: '',
    [MarketplaceProps.SELECTED_PLAN_ID]: '',
    [MarketplaceProps.SELECTED_SERVICE]: '',
  },
  loadingStoreSubscription: 'idle',
  loadingFetchUser: 'idle',
};

const setupToken = () => {
  const queryString = window.location.hash.replace('#/', '');
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has('token')) {
    const token = urlParams.get('token') || '';

    window.localStorage.setItem('storesubscription', token);
  } else {
    window.localStorage.clear();
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoadingStoreSubscription: (state, action: PayloadAction<string>) => {
      state.loadingStoreSubscription = action.payload;
    },
    setLoadingFetchUser: (state, action: PayloadAction<string>) => {
      state.loadingFetchUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUser.fulfilled,
      (state, action: PayloadAction<PrincipalResponse>) => {
        state.loadingFetchUser = 'loaded';
        if (!action.payload) {
          return;
        }
        state.fullName = action.payload.fullName;
        state.email = action.payload.email;
        state.country = action.payload.country;
        state.picture = action.payload.picture;
        state.isLoggedIn = typeof action.payload !== 'string';
      },
    );

    builder.addCase(
      fetchMarketplaceUserDetails.fulfilled,
      // TODO fix any
      (state, action: PayloadAction<any>) => {
        state.marketplaceDetails = action.payload;
      },
    );

    builder.addCase(fetchMarketplaceUserDetails.rejected, () => {
      setupToken();
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/configure?state=${process.env.REACT_APP_REDIRECT_URL}/%23/`,
        '_self',
      );
    });

    builder.addCase(storeSubscription.pending, (state) => {
      state.loadingStoreSubscription = 'loading';
    });

    builder.addCase(storeSubscription.fulfilled, (state) => {
      state.loadingStoreSubscription = 'loaded';
    });

    builder.addCase(storeSubscription.rejected, (state) => {
      state.loadingStoreSubscription = 'error';
    });
  },
});

export const selectFullName = ({ user }: RootState): string => user.fullName;
export const selectEmail = ({ user }: RootState): string => user.email;
export const selectCountry = ({ user }: RootState): string => user.country;
export const selectPicture = ({ user }: RootState): string => user.picture;
export const selectIsUserLoggedIn = ({ user }: RootState): boolean =>
  user.isLoggedIn;
export const selectMarketplaceDetails = ({
  user,
}: RootState): MarketplaceDetails => user.marketplaceDetails;
export const selectLoadingStoreSubscription = ({ user }: RootState): string =>
  user.loadingStoreSubscription;
export const selectLoadingFetchUser = ({ user }: RootState): string =>
  user.loadingFetchUser;

export const {
  setLoadingStoreSubscription,
  setLoadingFetchUser,
} = userSlice.actions;
