interface ListItem {
  event: string;
  title: string;
  isOkIconVisible: boolean;
  isLoadingIndicatorVisible: boolean;
  isErrorIconVisible: boolean;
}

export const setListArray = (
  event: string,
  arr: Array<ListItem>,
  setArr: Function,
) => {
  let modifiedIndex: number;
  let modifiedList = arr.map((item, i) => {
    const obj = item;
    obj.isLoadingIndicatorVisible = false;

    if (item.event === event) {
      modifiedIndex = event !== 'CompleteEvent' ? i : i + 1;

      return {
        event: item.event,
        title: item.title,
        isOkIconVisible: true,
        isLoadingIndicatorVisible: false,
      };
    }

    return obj;
  });

  modifiedList = modifiedList.map((item, i) => {
    const obj = item;
    if (!modifiedIndex) return obj;

    if (i < modifiedIndex) {
      obj.isOkIconVisible = true;
    } else if (i === modifiedIndex) {
      obj.isOkIconVisible = false;
    }

    return obj;
  });

  if (modifiedList[modifiedIndex!]) {
    modifiedList[modifiedIndex!].isLoadingIndicatorVisible = true;
  }

  setArr(modifiedList);
};
