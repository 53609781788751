import { Link } from 'react-router-dom';
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Container,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';
import logo from './logo.svg';

const styles = () => ({
  appBar: {
    backgroundColor: '#000',
  },

  logo: {
    maxWidth: 165,
    margin: 5,
  },

  profilePicture: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  navButton: {
    textDecoration: 'none',
    color: 'white',
  },
});

type HeaderProps = {
  classes: { [key: string]: string };
};

const Header = (props: HeaderProps) => {
  const { classes } = props;

  return (
    <AppBar
      component="div"
      className={classes.appBar}
      color="primary"
      position="static"
      elevation={0}
    >
      <Toolbar>
        <Container maxWidth="xl" fixed>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Link to="/">
                <img src={logo} alt="axilion-logo" className={classes.logo} />
              </Link>
            </Grid>
            <Grid item>
              <Tooltip title="Profile">
                <IconButton color="inherit" href="/#/profile">
                  <AccountCircle fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
