import React from 'react';
import LogoUrl from 'assets/images/Logo@3x.svg';
import { PageBarProps } from 'interfaces/props';
import styles from './PageBar.module.scss';

const PageBar: React.FC<PageBarProps> = ({ title, large }) => (
  <div className={`${styles.root} ${large ? styles.large : ''}`}>
    <img className={styles.logo} src={LogoUrl} alt="Axilion logo" />
    <div className={styles.title}>{title}</div>
  </div>
);

export default PageBar;
