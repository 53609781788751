import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'features';
import { MarketplaceProps } from 'enums';
import { Grid } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import PlanPricing from '../common/PlanPricing/PlanPricing';
import PageBar from '../common/PageBar/PageBar';

const styles = (theme: Theme) => ({
  container: {
    margin: `${theme.spacing(5)}px 0`,
  },
});

type PricingProps = {
  classes: { [key: string]: string };
};

const Pricing = ({ classes }: PricingProps) => {
  const dispatch = useDispatch();
  const [kilometers, setKilometers] = useState(1);
  const [numberOfIntersections, setNumberOfIntersections] = useState(1);

  const onSliderChange = ({
    field,
    value,
  }: {
    field: string;
    value: number;
  }) => {
    if (field === MarketplaceProps.DISTANCE) {
      setKilometers(value);
    }
    if (field === MarketplaceProps.NUMBER_OF_INTERSECTIONS) {
      setNumberOfIntersections(value);
    }
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  return (
    <>
      <PageBar title="PLAN CALCULATOR" />
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={7}>
          <PlanPricing
            onChange={onSliderChange}
            distance={kilometers}
            numberOfIntersections={numberOfIntersections}
          />
        </Grid>
      </Grid>
      <PageBar large title="PLAN CALCULATOR" />
    </>
  );
};

export default withStyles(styles)(Pricing);
