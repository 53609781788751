import {
  INSTALL,
  MP_USER_DETAILS,
  PRINCIPAL,
  STORE_SUBSCRIPTION,
  SUBSCRIPTIONS,
  REGIONS,
  ACTIVATE_SUBSCRIPTION,
  CHANGE_PLAN,
  CHARGE_EXTRA,
} from 'consts';
import {
  ActivateSubscriptionBody,
  ChangePlanBody,
  ChargeExtraData,
  InstallInfo,
} from 'interfaces';
import { get, patch, post } from './api';

export const getPrincipal = () => get(PRINCIPAL);
export const getMarketplaceUserDetails = () => get(MP_USER_DETAILS);
export const setStoreSubscription = (token: string) =>
  get(`${STORE_SUBSCRIPTION}=${token}`);
export const postMarketplaceUserDetails = (requestBody: {
  properties: { [key: string]: string };
}) => post(MP_USER_DETAILS, requestBody);
export const getSubscriptions = () => get(SUBSCRIPTIONS);
export const getInstallInfo = () => get(INSTALL);
export const postInstallInfo = (requestBody: InstallInfo) =>
  post(INSTALL, requestBody);
export const getRegions = (subscriptionId: string) =>
  get(`${REGIONS}?subscription=${subscriptionId}`);
export const activateSubscription = (requestBody: ActivateSubscriptionBody) =>
  post(ACTIVATE_SUBSCRIPTION, requestBody);
export const changePlan = (requestBody: ChangePlanBody) =>
  patch(CHANGE_PLAN, requestBody);
export const getChargeExtra = () => get(CHARGE_EXTRA);
export const postChargeExtra = (requestBody: Array<ChargeExtraData>) =>
  post(CHARGE_EXTRA, requestBody);
