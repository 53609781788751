import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  loadingSlice,
  userSlice,
  unitSystemSlice,
  allocateSlice,
  chargeExtraSlice,
  toastMessageSlice,
} from 'features';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    loading: loadingSlice.reducer,
    unitSystem: unitSystemSlice.reducer,
    allocate: allocateSlice.reducer,
    chargeExtra: chargeExtraSlice.reducer,
    toastMessage: toastMessageSlice.reducer,
  },
  middleware: [...getDefaultMiddleware()],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
