import React, { useEffect, useReducer, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, MenuItem } from '@material-ui/core';
import {
  fetchMarketplaceUserDetails,
  fetchUser,
  selectIsUserLoggedIn,
  selectMarketplaceDetails,
  setMarketplaceUserDetails,
  storeSubscription,
  selectLoadingStoreSubscription,
  setLoadingStoreSubscription,
} from 'features';
import { MarketplaceDetails } from 'interfaces';
import { countries } from 'utils';
import { MarketplaceProps } from 'enums';

import PlanPricing from '../common/PlanPricing/PlanPricing';
import PageBar from '../common/PageBar/PageBar';
import styles from './UserInfo.module.scss';

type Field = { value: string; error: string };

const initialFormState: { [key: string]: Field } = {
  [MarketplaceProps.TITLE]: { value: '', error: '' },
  [MarketplaceProps.GIVEN_NAME]: { value: '', error: '' },
  [MarketplaceProps.FAMILY_NAME]: { value: '', error: '' },
  [MarketplaceProps.COUNTRY]: { value: '', error: '' },
  [MarketplaceProps.ORG_NAME]: { value: '', error: '' },
  [MarketplaceProps.ORG_TYPE]: { value: '', error: '' },
  [MarketplaceProps.OTHER_ORG_TYPE]: { value: '', error: '' },
  [MarketplaceProps.DEPARTMENT]: { value: '', error: '' },
  [MarketplaceProps.OTHER_DEPARTMENT]: { value: '', error: '' },
  [MarketplaceProps.JOB_TITLE]: { value: '', error: '' },
  [MarketplaceProps.EMAIL]: { value: '', error: '' },
  [MarketplaceProps.PHONE_NUMBER]: { value: '', error: '' },
  [MarketplaceProps.AXILION_INFO]: { value: '', error: '' },
  [MarketplaceProps.DISTANCE]: { value: '1', error: '' },
  [MarketplaceProps.NUMBER_OF_INTERSECTIONS]: { value: '1', error: '' },
};

const defaultOrgTypes: { [key: string]: string } = {
  metropole: 'Metropole (Province)',
  city: 'City',
  state: 'State',
  agency: 'Agency',
  systemIntegrator: 'System Integrator',
};

const defaultDepartments: { [key: string]: string } = {
  transportation: 'Transportation',
  smartCity: 'Smart City & Innovation',
  it: 'IT',
  infrastructure: 'Infrastructure',
};

const formReducer = (
  state: typeof initialFormState,
  { field, value, error }: { field: string; value?: string; error?: string },
) => {
  if (
    !(state[MarketplaceProps.DEPARTMENT].value in defaultDepartments) &&
    state[MarketplaceProps.DEPARTMENT].value !== 'other'
  ) {
    state[MarketplaceProps.OTHER_DEPARTMENT].value =
      state[MarketplaceProps.DEPARTMENT].value;
    state[MarketplaceProps.DEPARTMENT].value = 'other';
  }

  if (
    !(state[MarketplaceProps.ORG_TYPE].value in defaultOrgTypes) &&
    state[MarketplaceProps.ORG_TYPE].value !== 'other'
  ) {
    state[MarketplaceProps.OTHER_ORG_TYPE].value =
      state[MarketplaceProps.ORG_TYPE].value;
    state[MarketplaceProps.ORG_TYPE].value = 'other';
  }

  const newState = { ...state };

  if (newState[field]) {
    if (typeof value === 'string') newState[field].value = value;
    if (typeof error === 'string') newState[field].error = error;
  }

  return newState;
};

interface UserInfoProps extends RouteComponentProps {}

const UserInfo = ({ location }: UserInfoProps) => {
  const history = useHistory();
  const { search } = location;
  const storeSubscriptionToken =
    window.localStorage.getItem('storesubscription') || '';
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const marketplaceDetails = useSelector(selectMarketplaceDetails);
  const loadingStoreSubscription = useSelector(selectLoadingStoreSubscription);
  const [form, dispatchForm] = useReducer(formReducer, initialFormState);
  const [numberOfUnits, setNumberOfUnits] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState('');

  const validateForm = (fields: string[], values?: string[]) => {
    const requiredFields = [
      MarketplaceProps.GIVEN_NAME,
      MarketplaceProps.FAMILY_NAME,
      MarketplaceProps.COUNTRY,
      MarketplaceProps.ORG_NAME,
      MarketplaceProps.ORG_TYPE,
      MarketplaceProps.JOB_TITLE,
      MarketplaceProps.EMAIL,
    ];

    if (form[MarketplaceProps.ORG_TYPE].value === 'other') {
      requiredFields.push(MarketplaceProps.OTHER_ORG_TYPE);
    }

    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
    const emailRegex = /^\S+@\S+\.\S+$/;

    let isValid = true;

    fields.forEach((field: string, index) => {
      let error = '';
      const value = values ? values[index] : form[field].value;
      const maxLength = 256;
      if (value.length > maxLength) {
        error = 'Text is too long';
      }
      if (
        field === MarketplaceProps.EMAIL &&
        (!value.includes('.') || !value.includes('@'))
      ) {
        error = 'Provide a valid email';
      }
      if (
        field === MarketplaceProps.PHONE_NUMBER &&
        value &&
        !phoneRegex.test(value)
      ) {
        error = 'Provide a valid phone number';
      }
      if (field === MarketplaceProps.EMAIL && !emailRegex.test(value)) {
        error = 'Email address is not valid';
      }
      if ((requiredFields as string[]).includes(field) && !value.trim()) {
        error = 'This field is required';
      }
      dispatchForm({
        field,
        error,
      });
      if (error) {
        isValid = false;
      }
    });

    return isValid;
  };

  const onFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchForm({
      field: event.target.name,
      value: event.target.value,
    });
    validateForm([event.target.name], [event.target.value]);
  };

  const onSliderChange = ({
    field,
    value,
  }: {
    field: string;
    value: number | number[];
  }) => {
    dispatchForm({
      field,
      value: String(value),
    });
  };

  const onFormSubmit = () => {
    if (validateForm(Object.keys(form))) {
      const departmentValue = Object.keys(defaultDepartments)
        .map((item) => item.toLocaleLowerCase())
        .includes(form[MarketplaceProps.DEPARTMENT].value)
        ? form[MarketplaceProps.DEPARTMENT].value
        : form[MarketplaceProps.OTHER_DEPARTMENT].value;

      const orgTypeValue = Object.keys(defaultOrgTypes)
        .map((item) => item.toLocaleLowerCase())
        .includes(form[MarketplaceProps.ORG_TYPE].value)
        ? form[MarketplaceProps.ORG_TYPE].value
        : form[MarketplaceProps.OTHER_ORG_TYPE].value;

      const requestBody = {
        properties: {
          [MarketplaceProps.AXILION_INFO]:
            form[MarketplaceProps.AXILION_INFO].value,
          [MarketplaceProps.COUNTRY]: form[MarketplaceProps.COUNTRY].value,
          [MarketplaceProps.DEPARTMENT]: departmentValue,
          [MarketplaceProps.EMAIL]: form[MarketplaceProps.EMAIL].value,
          [MarketplaceProps.FAMILY_NAME]:
            form[MarketplaceProps.FAMILY_NAME].value,
          [MarketplaceProps.GIVEN_NAME]:
            form[MarketplaceProps.GIVEN_NAME].value,
          [MarketplaceProps.JOB_TITLE]: form[MarketplaceProps.JOB_TITLE].value,
          [MarketplaceProps.ORG_NAME]: form[MarketplaceProps.ORG_NAME].value,
          [MarketplaceProps.ORG_TYPE]: orgTypeValue,
          [MarketplaceProps.PHONE_NUMBER]:
            form[MarketplaceProps.PHONE_NUMBER].value,
          [MarketplaceProps.TITLE]: form[MarketplaceProps.TITLE].value,
          [MarketplaceProps.DISTANCE]: form[MarketplaceProps.DISTANCE].value,
          [MarketplaceProps.NUMBER_OF_INTERSECTIONS]:
            form[MarketplaceProps.NUMBER_OF_INTERSECTIONS].value,
          [MarketplaceProps.NUMBER_OF_UNITS]: String(numberOfUnits),
          [MarketplaceProps.SELECTED_SERVICE]: String(selectedService),
          [MarketplaceProps.SELECTED_PLAN_ID]: String(selectedPlanId),
        } as MarketplaceDetails,
      };

      dispatch(setMarketplaceUserDetails(requestBody));
    }
  };

  useEffect(() => {
    if (storeSubscriptionToken.trim() !== '') {
      dispatch(storeSubscription(storeSubscriptionToken));
      window.localStorage.clear();
    } else {
      dispatch(fetchUser());
      dispatch(fetchMarketplaceUserDetails());
    }
  }, []);

  useEffect(() => {
    if (loadingStoreSubscription === 'loaded') {
      dispatch(fetchUser());
      dispatch(fetchMarketplaceUserDetails());
    }
    dispatch(setLoadingStoreSubscription('idle'));
  }, [loadingStoreSubscription]);

  useEffect(() => {
    if (!marketplaceDetails) return;

    Object.keys(marketplaceDetails).forEach((fieldName: string) => {
      if (
        fieldName === MarketplaceProps.DEPARTMENT &&
        !Object.keys(defaultDepartments).includes(
          marketplaceDetails[MarketplaceProps.DEPARTMENT],
        )
      ) {
        dispatchForm({
          field: MarketplaceProps.DEPARTMENT,
          value: 'other',
        });
        dispatchForm({
          field: MarketplaceProps.OTHER_DEPARTMENT,
          value: marketplaceDetails[fieldName],
        });
      } else if (
        fieldName === MarketplaceProps.ORG_TYPE &&
        !Object.keys(defaultOrgTypes).includes(
          marketplaceDetails[MarketplaceProps.ORG_TYPE],
        )
      ) {
        dispatchForm({
          field: MarketplaceProps.ORG_TYPE,
          value: 'other',
        });
        dispatchForm({
          field: MarketplaceProps.OTHER_ORG_TYPE,
          value: marketplaceDetails[fieldName],
        });
      } else {
        dispatchForm({
          field: fieldName,
          value: ((marketplaceDetails as unknown) as { [key: string]: string })[
            fieldName
          ],
        });
      }
    });

    setSelectedService(marketplaceDetails[MarketplaceProps.SELECTED_SERVICE]);
  }, [marketplaceDetails]);

  useEffect(() => {
    const tokenOfLoggedInUser = new URLSearchParams(search).get('token') || '';

    if (isLoggedIn && tokenOfLoggedInUser !== '') {
      dispatch(storeSubscription(tokenOfLoggedInUser));
      history.push('/');
    }
  }, [isLoggedIn]);

  return (
    <div className={styles.root}>
      <PageBar title="PLAN CALCULATOR" />
      <div className={styles.pageContent}>
        <PlanPricing
          onChange={onSliderChange}
          distance={Number(form[MarketplaceProps.DISTANCE].value) || 1}
          numberOfIntersections={Number(
            form[MarketplaceProps.NUMBER_OF_INTERSECTIONS].value,
          )}
          setNumberOfUnits={setNumberOfUnits}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          setSelectedPlanId={setSelectedPlanId}
        />

        <div className={styles.formFields}>
          <div className={styles['formFields-col']}>
            <TextField
              select
              label="Title"
              onChange={onFormChange}
              name={MarketplaceProps.TITLE}
              value={form[MarketplaceProps.TITLE].value}
              error={!!form[MarketplaceProps.TITLE].error}
              helperText={form[MarketplaceProps.TITLE].error}
              variant="outlined"
              className={styles.selectField}
            >
              <MenuItem value="mr">Mr.</MenuItem>
              <MenuItem value="mrs">Mrs.</MenuItem>
              <MenuItem value="miss">Miss</MenuItem>
              <MenuItem value="ms">Ms.</MenuItem>
              <MenuItem value="dr">Dr.</MenuItem>
              <MenuItem value="eng">Eng.</MenuItem>
            </TextField>
            <TextField
              label="*First Name"
              onChange={onFormChange}
              name={MarketplaceProps.GIVEN_NAME}
              value={form[MarketplaceProps.GIVEN_NAME].value}
              error={!!form[MarketplaceProps.GIVEN_NAME].error}
              helperText={form[MarketplaceProps.GIVEN_NAME].error}
              variant="outlined"
            />
            <TextField
              label="*Last Name"
              onChange={onFormChange}
              name={MarketplaceProps.FAMILY_NAME}
              value={form[MarketplaceProps.FAMILY_NAME].value}
              error={!!form[MarketplaceProps.FAMILY_NAME].error}
              helperText={form[MarketplaceProps.FAMILY_NAME].error}
              variant="outlined"
            />

            <TextField
              select
              label="*Country"
              onChange={onFormChange}
              name={MarketplaceProps.COUNTRY}
              value={form[MarketplaceProps.COUNTRY].value}
              error={!!form[MarketplaceProps.COUNTRY].error}
              helperText={form[MarketplaceProps.COUNTRY].error}
              variant="outlined"
              className={styles.selectField}
            >
              {countries.map(({ name, code }) => (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="*Organization Name"
              onChange={onFormChange}
              name={MarketplaceProps.ORG_NAME}
              value={form[MarketplaceProps.ORG_NAME].value}
              error={!!form[MarketplaceProps.ORG_NAME].error}
              helperText={form[MarketplaceProps.ORG_NAME].error}
              variant="outlined"
            />

            <TextField
              select
              label="*Organization Type"
              onChange={onFormChange}
              name={MarketplaceProps.ORG_TYPE}
              value={form[MarketplaceProps.ORG_TYPE].value}
              error={!!form[MarketplaceProps.ORG_TYPE].error}
              helperText={form[MarketplaceProps.ORG_TYPE].error}
              variant="outlined"
              className={styles.selectField}
            >
              {Object.keys(defaultOrgTypes).map((orgType, index) => (
                <MenuItem value={orgType} key={String(index)}>
                  {defaultOrgTypes[orgType]}
                </MenuItem>
              ))}
              <MenuItem value="other">Other</MenuItem>
            </TextField>

            {form[MarketplaceProps.ORG_TYPE].value === 'other' && (
              <TextField
                label="Specify Other Organization Type"
                onChange={onFormChange}
                name={MarketplaceProps.OTHER_ORG_TYPE}
                value={form[MarketplaceProps.OTHER_ORG_TYPE].value}
                error={!!form[MarketplaceProps.OTHER_ORG_TYPE].error}
                helperText={form[MarketplaceProps.OTHER_ORG_TYPE].error}
                variant="outlined"
              />
            )}
          </div>

          <div className={styles['formFields-col']}>
            <TextField
              select
              label="Department"
              onChange={onFormChange}
              name={MarketplaceProps.DEPARTMENT}
              value={form[MarketplaceProps.DEPARTMENT].value}
              error={!!form[MarketplaceProps.DEPARTMENT].error}
              helperText={form[MarketplaceProps.DEPARTMENT].error}
              variant="outlined"
              className={styles.selectField}
            >
              {Object.keys(defaultDepartments).map((department, index) => (
                <MenuItem value={department} key={String(index)}>
                  {defaultDepartments[department]}
                </MenuItem>
              ))}
              <MenuItem value="other">Other</MenuItem>
            </TextField>

            {form[MarketplaceProps.DEPARTMENT].value === 'other' && (
              <TextField
                label="Specify Other Department"
                onChange={onFormChange}
                name={MarketplaceProps.OTHER_DEPARTMENT}
                value={form[MarketplaceProps.OTHER_DEPARTMENT].value}
                error={!!form[MarketplaceProps.OTHER_DEPARTMENT].error}
                helperText={form[MarketplaceProps.OTHER_DEPARTMENT].error}
                variant="outlined"
              />
            )}

            <TextField
              label="*Job Title"
              onChange={onFormChange}
              name={MarketplaceProps.JOB_TITLE}
              value={form[MarketplaceProps.JOB_TITLE].value}
              error={!!form[MarketplaceProps.JOB_TITLE].error}
              helperText={form[MarketplaceProps.JOB_TITLE].error}
              variant="outlined"
            />

            <TextField
              label="Phone Number"
              onChange={onFormChange}
              name={MarketplaceProps.PHONE_NUMBER}
              value={form[MarketplaceProps.PHONE_NUMBER].value}
              error={!!form[MarketplaceProps.PHONE_NUMBER].error}
              helperText={form[MarketplaceProps.PHONE_NUMBER].error}
              variant="outlined"
            />

            <TextField
              label="*Work Email"
              onChange={onFormChange}
              name={MarketplaceProps.EMAIL}
              value={form[MarketplaceProps.EMAIL].value}
              error={!!form[MarketplaceProps.EMAIL].error}
              helperText={form[MarketplaceProps.EMAIL].error}
              variant="outlined"
            />

            <TextField
              label="How did you hear about Axilion?"
              onChange={onFormChange}
              name={MarketplaceProps.AXILION_INFO}
              value={form[MarketplaceProps.AXILION_INFO].value}
              error={!!form[MarketplaceProps.AXILION_INFO].error}
              helperText={form[MarketplaceProps.AXILION_INFO].error}
              variant="outlined"
              multiline
              className={styles.fieldHowDidYouHear}
            />
          </div>
        </div>
        <div className={styles.subFormBox}>
          <button type="button" onClick={onFormSubmit} className="button">
            Submit
          </button>
        </div>
      </div>
      <PageBar large title="PLAN CALCULATOR" />
    </div>
  );
};

export default UserInfo;
