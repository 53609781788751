import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  fetchInstallInfo,
  setInstallInfo,
  fetchSubscriptions,
  fetchRegions,
} from 'features';
import {
  AllocateSliceState,
  InstallInfo,
  Subscription,
  Region,
} from 'interfaces';

const initialState: AllocateSliceState = {
  subscriptions: [],
  installInfo: {} as InstallInfo,
  loadingSetInstallInfo: 'idle',
  regions: [],
};

export const allocateSlice = createSlice({
  name: 'allocate',
  initialState,
  reducers: {
    resetInstallInfo: (state) => {
      state.installInfo = {} as InstallInfo;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSubscriptions.fulfilled,
      (state, action: PayloadAction<Array<Subscription>>) => {
        state.subscriptions = action.payload;
      },
    );

    builder.addCase(fetchSubscriptions.rejected, () => {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/authorize?state=${process.env.REACT_APP_REDIRECT_URL}/%23/allocate`,
        '_self',
      );
    });

    builder.addCase(
      fetchInstallInfo.fulfilled,
      (state, action: PayloadAction<InstallInfo>) => {
        state.installInfo = action.payload;
      },
    );

    builder.addCase(setInstallInfo.pending, (state) => {
      state.loadingSetInstallInfo = 'loading';
    });

    builder.addCase(setInstallInfo.fulfilled, (state) => {
      state.loadingSetInstallInfo = 'loaded';
    });

    builder.addCase(setInstallInfo.rejected, (state) => {
      state.loadingSetInstallInfo = 'error';
    });

    builder.addCase(
      fetchRegions.fulfilled,
      (state, action: PayloadAction<Array<Region>>) => {
        state.regions = action.payload;
      },
    );
  },
});

export const selectSubscriptions = ({
  allocate,
}: RootState): Array<Subscription> => allocate.subscriptions;

export const selectRegions = ({ allocate }: RootState): Array<Region> =>
  allocate.regions;

export const selectInstallInfo = ({ allocate }: RootState): InstallInfo =>
  allocate.installInfo;

export const selectLoadingSetInstallInfo = ({ allocate }: RootState): string =>
  allocate.loadingSetInstallInfo;

export const { resetInstallInfo } = allocateSlice.actions;
