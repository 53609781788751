import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMarketplaceUserDetails,
  getPrincipal,
  postMarketplaceUserDetails,
  setStoreSubscription,
} from 'api';
import { PrincipalResponse, MarketplaceDetails } from 'interfaces';
import { RejectedValue } from 'interfaces/RejectedValue';

export const fetchUser = createAsyncThunk<
  PrincipalResponse,
  void,
  { rejectValue: RejectedValue }
>('user/fetchUser', async (_, thunkAPI) => {
  const principalRes = await getPrincipal();

  if (principalRes.status && principalRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: principalRes.statusText });
  }

  return principalRes.data;
});

export const fetchMarketplaceUserDetails = createAsyncThunk<
  MarketplaceDetails,
  void,
  { rejectValue: RejectedValue }
>('user/fetchMarketPlaceUserDetails', async (_, thunkAPI) => {
  const marketplaceUserDetailsRes = await getMarketplaceUserDetails();

  if (
    marketplaceUserDetailsRes.status &&
    marketplaceUserDetailsRes.status !== 200
  ) {
    return thunkAPI.rejectWithValue({
      error: marketplaceUserDetailsRes.statusText,
    });
  }

  return marketplaceUserDetailsRes.data.properties;
});

export const setMarketplaceUserDetails = createAsyncThunk<
  undefined,
  // TODO remove any
  any,
  { rejectValue: RejectedValue }
>('user/fetchMarketPlaceUserDetails', async (requestBody, thunkAPI) => {
  const marketplaceUserDetailsRes = await postMarketplaceUserDetails(
    requestBody,
  );

  if (
    marketplaceUserDetailsRes.status &&
    marketplaceUserDetailsRes.status !== 200
  ) {
    return thunkAPI.rejectWithValue({
      error: marketplaceUserDetailsRes.statusText,
    });
  }

  return undefined;
});

export const storeSubscription = createAsyncThunk<
  undefined,
  string,
  { rejectValue: RejectedValue }
>('user/storeSubscription', async (token: string, thunkAPI) => {
  const storeSubscriptionRes = await setStoreSubscription(token);

  if (storeSubscriptionRes.status && storeSubscriptionRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: storeSubscriptionRes.statusText });
  }

  return undefined;
});
