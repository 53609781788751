import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchChargeExtra, selectExtraCharges, setChargeExtra } from 'features';
import { Paper, Grid, Button, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Header from '../common/Header/Header';

const styles = () => ({
  container: {
    marginTop: 30,
  },
});

type ChargeExtraProps = {
  classes: { [key: string]: string };
};

const ChargeExtra = (props: ChargeExtraProps) => {
  const dispatch = useDispatch();
  const extraCharges = useSelector(selectExtraCharges);
  const { classes } = props;

  const submit = () => {
    dispatch(setChargeExtra(extraCharges));
  };

  useEffect(() => {
    dispatch(fetchChargeExtra());
  }, []); // eslint-disable-line

  return (
    <>
      <Header />
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={6}>
          <Paper>
            {extraCharges.map((extraCharge) => {
              return (
                <Box display="flex" flexDirection="column" p={2}>
                  {extraCharge.tenantId && !extraCharge.usageEventId && (
                    <>
                      <pre>{JSON.stringify(extraCharge, null, 2)}</pre>
                    </>
                  )}
                  {extraCharge.usageEventId && (
                    <>
                      Usage event ID:
                      {extraCharge.usageEventId}
                      <br />
                      Status:
                      {extraCharge.status}
                    </>
                  )}
                </Box>
              );
            })}
            <Box p={2} display="flex" justifyContent="flex-start">
              <Button onClick={submit} variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ChargeExtra);
