import { useLocation } from 'react-router-dom';
import { Grid, Container, Typography } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = () =>
  createStyles({
    footer: {
      backgroundColor: '#000',
      marginTop: 'auto',
      padding: 5,
      color: '#fff',
    },

    copyrightText: {
      textAlign: 'center',
    },
  });

type FooterProps = {
  classes: { [key: string]: string };
};

const Footer = (props: FooterProps) => {
  const { classes } = props;
  const location = useLocation();

  if (['/', '/pricing'].includes(location.pathname)) {
    return null;
  }

  return (
    <div className={classes.footer}>
      <Container maxWidth="xl" fixed>
        <Grid container>
          <Grid item xs>
            <Typography
              variant="body2"
              display="block"
              className={classes.copyrightText}
            >
              &copy;
              {` ${new Date().getFullYear()} `}
              Axilion Ltd. All rights reserved
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Footer);
