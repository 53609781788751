import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import store from 'store';
import { ToastMessage } from 'components';
import AppRouter from './Router';
import theme from './theme';
import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastMessage />
      <AppRouter />
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root'),
);
