import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectToastMessage, setToastMessage } from 'features';
import { useDebounce } from 'utils/hooks';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import styles from './ToastMessage.module.scss';

const successCode = 200;
const shortDelay = 2000;
const longDelay = 5000;

export const ToastMessage: React.FC = () => {
  const message = useSelector(selectToastMessage);
  const debouncedMessage = useDebounce(message, successCode);
  const [isOpen, setIsOpen] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(shortDelay);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setToastMessage(''));
    setIsOpen(false);
  };

  useEffect(() => {
    if (!debouncedMessage) return;

    setAutoHideDuration(
      debouncedMessage.length > successCode ? longDelay : shortDelay,
    );
    setIsOpen(true);
  }, [debouncedMessage]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <MuiAlert elevation={6} variant="filled" severity="error">
        {debouncedMessage}
        <IconButton
          className={styles.closeButton}
          onClick={handleClose}
          size="small"
          color="inherit"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </MuiAlert>
    </Snackbar>
  );
};
