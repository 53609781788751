import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ToastMessageState } from 'interfaces';

const initialState: ToastMessageState = {
  message: '',
};

export const toastMessageSlice = createSlice({
  name: 'toastMessage',
  initialState,
  reducers: {
    setToastMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
});

export const selectToastMessage = ({ toastMessage }: RootState): string =>
  toastMessage.message;

export const { setToastMessage } = toastMessageSlice.actions;
