import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChargeExtra, postChargeExtra } from 'api';
import { ChargeExtraData } from 'interfaces';
import { RejectedValue } from 'interfaces/RejectedValue';

export const fetchChargeExtra = createAsyncThunk<
  Array<ChargeExtraData>,
  void,
  { rejectValue: RejectedValue }
>('chargeExtra/fetch', async (_, thunkAPI) => {
  const chargeExtraRes = await getChargeExtra();

  if (chargeExtraRes.status && chargeExtraRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: chargeExtraRes.statusText });
  }

  return chargeExtraRes.data;
});

export const setChargeExtra = createAsyncThunk<
  Array<ChargeExtraData>,
  Array<ChargeExtraData>,
  { rejectValue: RejectedValue }
>('chargeExtra/set', async (requestBody, thunkAPI) => {
  const chargeExtraRes = await postChargeExtra(requestBody);

  if (chargeExtraRes.status && chargeExtraRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: chargeExtraRes.statusText });
  }

  return chargeExtraRes.data;
});
