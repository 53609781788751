import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Grid,
  LinearProgress,
  Fade,
  Button,
  Box,
  TextField,
  FormControl,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Link,
} from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import { Theme, withStyles } from '@material-ui/core/styles';
import { timeZones } from 'utils';
import {
  fetchSubscriptions,
  fetchUser,
  selectSubscriptions,
  selectRegions,
  fetchRegions,
  selectInstallInfo,
  fetchInstallInfo,
  setInstallInfo,
  selectLoadingSetInstallInfo,
  resetInstallInfo,
} from 'features';
import { Subscription, Region } from 'interfaces';
import Header from '../common/Header/Header';
import { listMessagesInstall } from '../common/listMessages';
import { setListArray } from '../common/setListArray';

const styles = (theme: Theme) => ({
  container: {
    marginTop: 30,
  },

  error: {
    color: 'red',
  },

  button: {
    margin: theme.spacing(1),
  },

  errorMessage: {
    border: '1px solid red',
    color: 'red',
    padding: 10,
    borderRadius: 5,
    overflow: 'auto',
  },

  infoLink: {
    marginTop: 5,
  },
});

interface InstallProps extends RouteComponentProps {
  classes: { [key: string]: string };
}

const clientTimeZone = timeZones.find((tz) =>
  tz.utc.includes(Intl.DateTimeFormat().resolvedOptions().timeZone),
)?.text;

const Install = ({ classes, history }: InstallProps) => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(selectSubscriptions);
  const regions = useSelector(selectRegions);
  const installInfo = useSelector(selectInstallInfo);
  const loadingSetInstallInfo = useSelector(selectLoadingSetInstallInfo);
  const action = history.location.search;
  const hasInstallAction = action.includes('action=install');
  const [values, setValues] = useState({
    subscription: '',
    resourceGroup: '',
    storageAccount: '',
    kuberneteCluster: '',
    databaseServer: '',
    useSpot: false,
    storageRetentionPeriodDays: 0,
    regionName: '',
  });
  const [errors, setErrors] = useState({});
  const [LIST_MESSAGES, setListMessages] = useState(listMessagesInstall);
  const [reAllocateButtonEnabled, setReAllocateButtonEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState(clientTimeZone);

  const hasErrors = Object.values(errors).some((hasError) => hasError);

  const allocateAzureResources = () => {
    const evtSource = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/allocate`,
      { withCredentials: !!process.env.REACT_APP_IS_DEVELOPMENT },
    );
    let numberOfOkEvents = 0;

    evtSource.onmessage = (e) => {
      setErrorMessage(null);
      const { lastEventId, data } = e;

      switch (lastEventId) {
        case 'StartEvent':
          setListArray('StartEvent', LIST_MESSAGES, setListMessages);
          numberOfOkEvents += 1;
          break;
        case 'ResourceGroupEvent':
          setListArray('ResourceGroupEvent', LIST_MESSAGES, setListMessages);
          setReAllocateButtonEnabled(false);
          numberOfOkEvents += 1;
          break;
        case 'StorageAccountEvent':
          setListArray('StorageAccountEvent', LIST_MESSAGES, setListMessages);
          numberOfOkEvents += 1;
          break;
        case 'MySqlServerEvent':
          setListArray('MySqlServerEvent', LIST_MESSAGES, setListMessages);
          numberOfOkEvents += 1;
          break;
        case 'ApplicationInsightsEvent':
          setListArray(
            'ApplicationInsightsEvent',
            LIST_MESSAGES,
            setListMessages,
          );
          numberOfOkEvents += 1;
          break;
        case 'KubernetesClusterEvent':
          setListArray(
            'KubernetesClusterEvent',
            LIST_MESSAGES,
            setListMessages,
          );
          numberOfOkEvents += 1;
          break;
        case 'SubscriptionQuota':
          setListArray('SubscriptionQuota', LIST_MESSAGES, setListMessages);
          numberOfOkEvents += 1;
          break;
        case 'CompleteEvent':
          setListArray('CompleteEvent', LIST_MESSAGES, setListMessages);
          numberOfOkEvents += 1;
          evtSource.close();
          setReAllocateButtonEnabled(true);
          break;
        case 'ErrorEvent':
          setErrorMessage(JSON.parse(data).message);
          break;
        default:
          break;
      }
    };

    evtSource.addEventListener('error', () => {
      const modifiedList = LIST_MESSAGES.map((item, i) => {
        const obj = item;
        const itemIndex = i + 1;

        if (itemIndex < numberOfOkEvents) {
          obj.isLoadingIndicatorVisible = false;
          obj.isErrorIconVisible = false;
          obj.isOkIconVisible = true;
        } else if (itemIndex === numberOfOkEvents) {
          obj.isLoadingIndicatorVisible = false;
          obj.isErrorIconVisible = true;
          obj.isOkIconVisible = false;
        } else {
          obj.isLoadingIndicatorVisible = false;
          obj.isErrorIconVisible = false;
          obj.isOkIconVisible = false;
        }

        return obj;
      });

      setReAllocateButtonEnabled(true);
      setListMessages(modifiedList);
    });

    evtSource.onerror = () => {
      evtSource.close();
      setReAllocateButtonEnabled(true);
    };
  };

  const handleError = (name: string, value: string) => {
    let regexString = '';
    switch (name) {
      case 'storageAccount': {
        regexString = '^[a-z0-9]{3,24}$';
        break;
      }
      case 'databaseServer': {
        regexString = '(?!-)^[a-z0-9-]{2,62}[^!@#$%^&*)(+=._A-Z-]$';
        break;
      }
      case 'kuberneteCluster': {
        regexString = '(?![-_])^[a-zA-Z0-9-_]{0,62}[^!@#$%^&*)(+=._-]$';
        break;
      }
      case 'resourceGroup': {
        regexString = '^[a-zA-Z0-9-_.()]{0,89}[^!.]$';
        break;
      }
      default:
        break;
    }

    return !new RegExp(regexString).test(value);
  };

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value.trim();
    setValues({ ...values, [name]: inputValue });

    if (typeof inputValue === 'string') {
      setErrors({ ...errors, [name]: handleError(name, inputValue) });
    }
  };

  const getHelperText = (name: string) => {
    switch (name) {
      case 'storageAccount':
        return 'Storage account name must be between 3 and 24 characters in length and may contain numbers and lowercase letters only.';
      case 'databaseServer':
        return 'Database server name must be between 3 and 63 characters in length and may contain numbers, lowercase and hyphens only. The name must not end or start with hyphen.';
      case 'kuberneteCluster':
        return 'Kubernetes cluster name must be between 1 and 63 characters in length and contain only letters, numbers, underscores or hyphens. Name must start or ends with letter or number.';
      case 'resourceGroup':
        return 'Resource group name must be between 1 and 90 characters in length and only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.';
      default:
        return '';
    }
  };

  const handleTimeZoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeZone(event.target.value);
  };

  const handleAllocateClick = () => {
    dispatch(
      setInstallInfo({
        subscriptionId: values.subscription,
        resourceGroup: values.resourceGroup,
        storageAccount: values.storageAccount,
        kuberneteCluster: values.kuberneteCluster,
        databaseServer: values.databaseServer,
        useSpot: values.useSpot,
        storageRetentionPeriodDays: Number(values.storageRetentionPeriodDays),
        regionName: values.regionName,
        timezone: timeZones.find((tz) => tz.text === selectedTimeZone)?.utc[0],
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchSubscriptions());
    if (hasInstallAction) {
      allocateAzureResources();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (values.subscription) {
      dispatch(fetchRegions({ subscriptionId: values.subscription }));
      dispatch(fetchInstallInfo());
    }
  }, [values.subscription]);

  useEffect(() => {
    if (Object.keys(installInfo).length > 0) {
      setValues({ ...values, ...installInfo, regionName: 'westeurope' });
    }
  }, [installInfo]);

  useEffect(() => {
    if (loadingSetInstallInfo === 'loaded') {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/authorize?state=${process.env.REACT_APP_REDIRECT_URL}/%23/allocate?action=install`,
        '_self',
      );
    }
  }, [loadingSetInstallInfo]);

  useEffect(() => {
    const {
      location: { search },
    } = history;
    sessionStorage.setItem('searchHistory', search);

    return () => {
      dispatch(resetInstallInfo());
    };
  }, []);

  return (
    <div className="Install">
      <Header />
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={5}>
          {hasInstallAction ? (
            <Paper elevation={3}>
              <Box display="flex" flexDirection="column" p={2}>
                <List component="nav" aria-label="events">
                  {LIST_MESSAGES.map((item) => {
                    if (item.event === 'CompleteEvent') {
                      return null;
                    }

                    return (
                      <div key={item.title}>
                        <ListItem>
                          <ListItemText
                            primary={item.title}
                            className={
                              item.isErrorIconVisible ? classes.error : ''
                            }
                          />
                          {item.isOkIconVisible && (
                            <ListItemIcon>
                              <CheckCircleOutlined htmlColor="green" />
                            </ListItemIcon>
                          )}
                          {item.isErrorIconVisible && (
                            <ListItemIcon>
                              <ErrorOutline htmlColor="red" />
                            </ListItemIcon>
                          )}
                        </ListItem>
                        <Fade in={item.isLoadingIndicatorVisible}>
                          <LinearProgress />
                        </Fade>
                      </div>
                    );
                  })}
                  {errorMessage && (
                    <ListItem className={classes.errorMessage}>
                      {errorMessage}
                    </ListItem>
                  )}
                </List>
                <Button
                  variant="contained"
                  // eslint-disable-next-line max-len
                  href={`${process.env.REACT_APP_API_BASE_URL}/authorize?state=${process.env.REACT_APP_REDIRECT_URL}/%23/allocate?action=install`}
                  color="primary"
                  disabled={!reAllocateButtonEnabled}
                >
                  Allocate azure resources
                </Button>
              </Box>
            </Paper>
          ) : (
            <Paper elevation={3}>
              <Box display="flex" flexDirection="column" p={2}>
                <form name="AzureResources">
                  <FormControl fullWidth>
                    <TextField
                      id="standard-select-subscription"
                      select
                      label="Subscription"
                      className={classes.textField}
                      value={values.subscription}
                      onChange={handleChange('subscription')}
                      margin="normal"
                      variant="outlined"
                    >
                      {subscriptions.map((sub: Subscription) => (
                        <MenuItem key={sub.id} value={sub.id}>
                          {sub.displayName}
                        </MenuItem>
                      ))}
                    </TextField>
                    {regions.length > 0 && Object.keys(installInfo).length > 0 && (
                      <>
                        <TextField
                          select
                          label="Regions"
                          value={values.regionName}
                          onChange={handleChange('regionName')}
                          margin="normal"
                          variant="outlined"
                          required
                        >
                          {regions.map((region: Region) => (
                            <MenuItem key={region.id} value={region.id}>
                              {region.displayName}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          label="Resource group"
                          name="resourceGroup"
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={values.resourceGroup}
                          onChange={handleChange('resourceGroup')}
                          error={handleError(
                            'resourceGroup',
                            values.resourceGroup,
                          )}
                          helperText={
                            handleError('resourceGroup', values.resourceGroup)
                              ? getHelperText('resourceGroup')
                              : ''
                          }
                        />
                        <TextField
                          label="Storage account"
                          name="storageAccount"
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={values.storageAccount}
                          onChange={handleChange('storageAccount')}
                          error={handleError(
                            'storageAccount',
                            values.storageAccount,
                          )}
                          helperText={
                            handleError('storageAccount', values.storageAccount)
                              ? getHelperText('storageAccount')
                              : ''
                          }
                        />
                        <TextField
                          label="Kubernete cluster"
                          name="kuberneteCluster"
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={values.kuberneteCluster}
                          onChange={handleChange('kuberneteCluster')}
                          error={handleError(
                            'kuberneteCluster',
                            values.kuberneteCluster,
                          )}
                          helperText={
                            handleError(
                              'kuberneteCluster',
                              values.kuberneteCluster,
                            )
                              ? getHelperText('kuberneteCluster')
                              : ''
                          }
                        />
                        <TextField
                          label="Database server"
                          name="databaseServer"
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={values.databaseServer}
                          onChange={handleChange('databaseServer')}
                          error={handleError(
                            'databaseServer',
                            values.databaseServer,
                          )}
                          helperText={
                            handleError('databaseServer', values.databaseServer)
                              ? getHelperText('databaseServer')
                              : ''
                          }
                        />
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            // eslint-disable-next-line prettier/prettier
                            control={(
                              <Checkbox
                                color="primary"
                                name="useSpot"
                                checked={values.useSpot}
                                onChange={handleChange('useSpot')}
                              />
                              // eslint-disable-next-line prettier/prettier
                          )}
                            label="Use spot machines"
                          />
                          <Link
                            target="_blank"
                            rel="noreferrer"
                            href="https://azure.microsoft.com/en-us/blog/announcing-the-general-availability-of-azure-spot-virtual-machines/#:~:text=Azure%20Spot%20VMs%20provide%20access,as%2Dyou%2Dgo%20rates."
                            className={classes.infoLink}
                          >
                            <InfoOutlinedIcon
                              htmlColor="grey"
                              fontSize="small"
                            />
                          </Link>
                        </Box>
                        <TextField
                          label="Storage retention period days"
                          name="storageRetentionPeriodDays"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
                          value={values.storageRetentionPeriodDays}
                          onChange={handleChange('storageRetentionPeriodDays')}
                        />
                        <TextField
                          label="Time zone"
                          name="timezone"
                          margin="normal"
                          variant="outlined"
                          select
                          className={classes.textField}
                          value={selectedTimeZone}
                          onChange={handleTimeZoneChange}
                        >
                          {timeZones.map(({ text }) => (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                  </FormControl>
                </form>
                <Button
                  variant="contained"
                  onClick={handleAllocateClick}
                  color="primary"
                  disabled={
                    !Object.keys(installInfo).length ||
                    !regions.length ||
                    hasErrors
                  }
                >
                  Allocate azure resources
                </Button>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Install);
