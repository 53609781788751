import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
} from '@material-ui/core';
import ArrowBackIosTwoTone from '@material-ui/icons/ArrowBackIosTwoTone';
import { Theme, withStyles } from '@material-ui/core/styles';
import {
  fetchUser,
  selectCountry,
  selectEmail,
  selectFullName,
} from 'features';
import Header from '../common/Header/Header';

const styles = (theme: Theme) => ({
  container: {
    marginTop: 30,
  },

  button: {
    marginRight: 'auto',
  },

  textField: {
    display: 'block',
  },

  connectedInfo: {
    border: '1px solid green',
    color: 'green',
    borderRadius: 7,
    padding: 5,
  },

  disconnectedInfo: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    borderRadius: 7,
    padding: 5,
  },

  leftIcon: {
    fontSize: 14,
    marginRight: 5,
  },
});

type ProfileProps = {
  classes: { [key: string]: string };
};

const Profile = (props: ProfileProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const fullName = useSelector(selectFullName);
  const email = useSelector(selectEmail);
  const country = useSelector(selectCountry);
  const { classes } = props;

  const handleBackAction = () => {
    const hasSearchHistory = sessionStorage.getItem('searchHistory');

    if (hasSearchHistory) {
      history.push('allocate');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, []); // eslint-disable-line

  return (
    <div className="Profile">
      <Header />
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={6}>
          <Paper>
            <Box display="flex" flexDirection="column" p={2}>
              <Grid container>
                <Grid item xs>
                  <Box p={2}>
                    <Typography gutterBottom variant="body1">
                      Azure Profile
                    </Typography>
                    <TextField
                      id="outlined-region"
                      label="Name"
                      className={classes.textField}
                      value={fullName}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                    <TextField
                      id="outlined-region"
                      label="Email"
                      className={classes.textField}
                      value={email}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                    <TextField
                      id="outlined-region"
                      label="Country"
                      className={classes.textField}
                      value={country}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box p={2} display="flex" justifyContent="flex-start">
                <Button className={classes.button} onClick={handleBackAction}>
                  <ArrowBackIosTwoTone className={classes.leftIcon} />
                  Back
                </Button>
                <Button
                  variant="contained"
                  // eslint-disable-next-line max-len
                  href={`${process.env.REACT_APP_API_BASE_URL}/authorize?state=${process.env.REACT_APP_REDIRECT_URL}/%23/allocate?action=install`}
                  color="primary"
                >
                  Allocate azure resources
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Profile);
