import axios from 'axios';
import store from 'store';
import { setToastMessage } from 'features';

export const handleResponseErrors = () => {
  axios.interceptors.response.use((response) => {
    if (response.config.url === 'user/info' && response.status === 401) {
      return response;
    }

    if (response.status !== 200) {
      let message = 'Something went wrong';
      if (
        typeof response.data === 'string' &&
        response.data.trim().length > 0
      ) {
        message = response.data;
      } else if (response.data?.status && response.data?.error) {
        message = `${response.data.status}: ${response.data.error}`;
      }
      store.dispatch(setToastMessage(message));
    }

    return response;
  });
};
