export const listMessagesInstall = [
  {
    event: 'StartEvent',
    title: 'Establishing connection to Azure',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
  {
    event: 'ResourceGroupEvent',
    title: 'Allocating resource group',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
  {
    event: 'StorageAccountEvent',
    title: 'Allocating storage account',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
  {
    event: 'MySqlServerEvent',
    title: 'Allocating database',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
  {
    event: 'ApplicationInsightsEvent',
    title: 'Configuring application insights',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
  {
    event: 'SubscriptionQuota',
    title: 'Checking subscription quota',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
  {
    event: 'KubernetesClusterEvent',
    title: 'Allocating compute cluster',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
  {
    event: 'CompleteEvent',
    title: 'Azure resource allocation completed',
    isOkIconVisible: false,
    isLoadingIndicatorVisible: false,
    isErrorIconVisible: false,
  },
];
