import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  fetchUser,
  handleActivateSubscription,
  handleChangePlan,
  selectIsUserLoggedIn,
  selectLoadingFetchUser,
  setLoadingFetchUser,
} from 'features';
import { ActivateSubscriptionBody, ChangePlanBody } from 'interfaces';
import Header from '../common/Header/Header';

const styles = () => ({
  container: {
    marginTop: 30,
  },

  leftButton: {
    marginLeft: 'auto',
    marginRight: 10,
  },

  textField: {
    display: 'block',
  },
});

type ManageSubscriptionProps = {
  classes: { [key: string]: string };
};

const ManageSubscription = (props: ManageSubscriptionProps) => {
  const dispatch = useDispatch();
  const tenantIdRef = useRef<HTMLInputElement>();
  const planIdRef = useRef<HTMLInputElement>();
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const loadingFetchUser = useSelector(selectLoadingFetchUser);
  const { classes } = props;

  const activateSubscription = () => {
    const requestBody: ActivateSubscriptionBody = {
      tenantId: tenantIdRef?.current?.value,
    };

    dispatch(handleActivateSubscription(requestBody));
  };

  const changePlan = () => {
    const requestBody: ChangePlanBody = {
      tenantId: tenantIdRef?.current?.value,
      planId: planIdRef?.current?.value,
    };

    dispatch(handleChangePlan(requestBody));
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loadingFetchUser === 'loaded' && !isLoggedIn) {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/configure?state=${process.env.REACT_APP_REDIRECT_URL}/%23/change-plan-activate`,
        '_self',
      );
    }
    dispatch(setLoadingFetchUser('idle'));
  }, [isLoggedIn, loadingFetchUser]);

  return (
    <>
      <Header />
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={6}>
          <Paper>
            <Box display="flex" flexDirection="column" p={2}>
              <Grid container>
                <Grid item xs>
                  <Box p={2}>
                    <Typography gutterBottom variant="body1">
                      Manage Subscription
                    </Typography>
                    <TextField
                      inputRef={tenantIdRef}
                      label="Tenant ID"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                    <TextField
                      inputRef={planIdRef}
                      label="Plan ID"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box p={2} display="flex" justifyContent="flex-start">
                <Button
                  className={classes.leftButton}
                  variant="contained"
                  color="primary"
                  onClick={activateSubscription}
                >
                  Activate Subscription
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changePlan}
                >
                  Change plan
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ManageSubscription);
