import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getInstallInfo,
  getSubscriptions,
  postInstallInfo,
  getRegions,
} from 'api';
import { InstallInfo, Subscription, Region } from 'interfaces';
import { RejectedValue } from 'interfaces/RejectedValue';

export const fetchSubscriptions = createAsyncThunk<
  Array<Subscription>,
  void,
  { rejectValue: RejectedValue }
>('allocate/fetchSubscriptions', async (_, thunkAPI) => {
  const subscriptionsRes = await getSubscriptions();

  if (subscriptionsRes.status && subscriptionsRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: subscriptionsRes.statusText });
  }

  return subscriptionsRes.data;
});

export const fetchInstallInfo = createAsyncThunk<
  InstallInfo,
  void,
  { rejectValue: RejectedValue }
>('allocate/fetchInstallInfo', async (_, thunkAPI) => {
  const installInfoRes = await getInstallInfo();

  if (installInfoRes.status && installInfoRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: installInfoRes.statusText });
  }

  return installInfoRes.data;
});

export const setInstallInfo = createAsyncThunk<
  void,
  InstallInfo,
  { rejectValue: RejectedValue }
>('allocate/setInstallInfo', async (requestBody, thunkAPI) => {
  const installInfoRes = await postInstallInfo(requestBody);

  if (installInfoRes.status && installInfoRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: installInfoRes.statusText });
  }

  return undefined;
});

export const fetchRegions = createAsyncThunk<
  Array<Region>,
  { subscriptionId: string },
  { rejectValue: RejectedValue }
>('allocate/fetchRegions', async ({ subscriptionId }, thunkAPI) => {
  const regionsRes = await getRegions(subscriptionId);

  if (regionsRes.status && regionsRes.status !== 200) {
    return thunkAPI.rejectWithValue({ error: regionsRes.statusText });
  }

  return regionsRes.data;
});
