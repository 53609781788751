import axios, { AxiosPromise, Method, AxiosRequestConfig } from 'axios';

const requester = (method: Method) => (
  url: string,
  data = {},
  options?: Partial<AxiosRequestConfig>,
): AxiosPromise =>
  axios({
    method,
    url,
    withCredentials: Boolean(process.env.REACT_APP_IS_DEVELOPMENT),
    validateStatus: () => true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    data,
    ...options,
  });

export const get = requester('get');

export const post = requester('post');

export const put = requester('put');

export const del = requester('delete');

export const patch = requester('patch');
