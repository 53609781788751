export enum MarketplaceProps {
  AXILION_INFO = 'marketplace.axilionInfo',
  COUNTRY = 'marketplace.country',
  DEPARTMENT = 'marketplace.departament',
  OTHER_DEPARTMENT = 'marketplace.otherDepartament',
  EMAIL = 'marketplace.email',
  FAMILY_NAME = 'marketplace.familyName',
  GIVEN_NAME = 'marketplace.givenName',
  JOB_TITLE = 'marketplace.jobTitle',
  ORG_NAME = 'marketplace.organizationName',
  ORG_TYPE = 'marketplace.organizationType',
  OTHER_ORG_TYPE = 'marketplace.otherOrganizationType',
  PHONE_NUMBER = 'marketplace.phoneNumber',
  TITLE = 'marketplace.title',
  DISTANCE = 'marketplace.distance',
  NUMBER_OF_INTERSECTIONS = 'marketplace.numberIntersections',
  NUMBER_OF_UNITS = 'marketplace.numberOfUnits',
  SELECTED_SERVICE = 'marketplace.selectedService',
  SELECTED_PLAN_ID = 'marketplace.selectedPlanId',
}
